'use strict';

var $ = require('jquery');

var modules = [
  require('./modules/type.js'),
  require('./modules/slides.js')
];

try{!function(t,e,n,r,a,s,i,l){n&&(s=n[r],s&&(i=t.createElement("style"),i.innerHTML=s,t.getElementsByTagName("head")[0].appendChild(i)),l=e.setAttribute,e.setAttribute=function(t,p,u,c){"string"==typeof p&&p.indexOf(a)>-1&&(u=new XMLHttpRequest,u.open("GET",p,!0),u.onreadystatechange=function(){if(4===u.readyState){c=u.responseText.replace(/url\(\//g,"url("+a+"/");try{c!==s&&(n[r]=c)}catch(t){s&&(s=i.innerHTML="")}}},u.send(null),e.setAttribute=l),l.apply(this,arguments)})}(document,Element.prototype,localStorage,"tk","https://use.typekit.net")}catch(x){}

$(document).ready(() => {
  var dob = new Date('1968-08-30');
  var today = new Date();
  var age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));
  $('#s3 p.left').text(
    $('#s3 p.left').text().replace('__jaar', age + ' jaar')
  );
  modules.forEach(m => m.init());
});

// Mobile replace footer parts
if ( $(window).width() <= 768 ) {
  var rfp = $('.grid-20:contains("T. +31")');
  var r = rfp.clone().find('p.yoffset').text().replace('A&R', '').replace('ADVIES EN CONSULTANCY', '').replace('RAYMOND WINKENS (FA)', '');
  var rs = rfp.clone().find('p.yoffset').text().replace(/(([T]\.).*)/, '').replace(/(([info@]).*)/, '');
  $('.grid-15 p.yoffset').append('<br><br>' + r);
  $('.grid-20:contains("T. +31") p.yoffset').text(rs);
}