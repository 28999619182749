'use strict';

var Module = require('./module.js');
var fullpage = require('fullpage.js');

class Slides extends Module {

  constructor(element, $) {
    super();
    this.$element = $(element);
    this.$window = $(window);
    this.$navItem = $('nav li');
    this.$window.width() > 500 ? this.fullPageSlider() : this.mobileNav();
  }

  mobileNav() {
    this.$navItem.each(function() {
      $(this).on('click', () => {
        $('html, body').animate({
          scrollTop: $('#s' + $(this).data('slide')).offset().top
        }, 500);
      });
    });
  }

  fullPageSlider() {
    this.$element.fullpage({
      sectionSelector: '.s',
      verticalCentered: false,
      fixedElements: '.logo, .arrow',

      onLeave: function(index, nextIndex, direction) {
          var leavingSection = $(this);
          var logo = $('.cls-1');
          var arrowUp = $('.svg-arrow-up');
          var arrowDown = $('.svg-arrow-down');

          if ( nextIndex === 1 ) 
            logo.css('fill', ''),
            arrowDown.css('stroke', '');
          else if ( nextIndex === 2 ) 
            logo.css('fill', '#be9f56'),
            arrowDown.css('stroke', '#141716');
          else if ( nextIndex === 3 ) 
            logo.css('fill', '#bbc0c0'),
            arrowDown.css('stroke', '#141716');
          else if ( nextIndex === 4 ) 
            logo.css('fill', '#7f7e7e'),
            arrowDown.css('stroke', '');
      }
    });

    $('.logo').on('click', () => {
      this.$element.fullpage.moveTo(1);
    });

    var fullpageEl = this.$element.fullpage;
    var w = this.$window;

    this.$navItem.each(function() {
      $(this).on('click', () => {
        fullpageEl.moveTo( $(this).data('slide') )
      });
    });

    $('.arrow.arrow-up').on('click', () => { fullpageEl.moveSectionUp() });
    $('.arrow.arrow-down').on('click', () => { fullpageEl.moveSectionDown() });

    w.on('mousemove', (e) => {
      var scrollY = Math.round(e.pageY / w.height() * 100),
      arrX = e.pageX - ( $('.arrow').width() / 2 ) + 'px',
      arrY = e.pageY - ( $('.arrow').height() / 2 ) + 'px',
      s4 = Math.round( $('#s4 form').offset().top / 10 );

      if ( scrollY > 80 && !$('body').hasClass('fp-viewing-3') ) {
        $('.arrow.arrow-up').fadeOut();
        $('.arrow.arrow-down').fadeIn().css({'left': arrX, 'top': arrY});
      } else if ( scrollY < 20 && !$('body').hasClass('fp-viewing-0') && !$('body').hasClass('fp-viewing-3') ) {
        $('.arrow.arrow-up').fadeIn().css({'left': arrX, 'top': arrY});;
        $('.arrow.arrow-down').fadeOut();
      } else if ( scrollY < s4 && $('body').hasClass('fp-viewing-3') ) {
        $('.arrow.arrow-up').fadeIn().css({'left': arrX, 'top': arrY});;
        $('.arrow.arrow-down').fadeOut();
      } else {
        $('.arrow').fadeOut();
      }


    });

  }

}

Slides.selector = '.content';
module.exports = Slides;